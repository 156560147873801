import React, {useState } from "react";
import images from "../../../Assets/Images";
import { useNavigate } from "react-router-dom";

const Step1 = () => {
  return (
    <>
      <img src={images["start.svg"]} className="width-100" alt="start" />
      <div className="text_inner">
        <div className="welcome_text_box">
          <p>Welcome to</p>
          <img src={images["logo.png"]} alt="logo" />
        </div>
        <h2 className="h-1">
          Your investment journey starts <br /> here
        </h2>
      </div>
    </>
  );
};
const Step2 = () => {
  return (
    <div className="text_inner">
      <div className="step2_imgbox">
        <img src={images["step_2.png"]} alt="step2"/>
        <p>
          <span>TODAY</span> <span>1MONTH</span> <span>3MONTH</span>
        </p>
      </div>
      <div className="disclaimer-box">
        <p className="disclaimer-text">
          90% of beginner traders lose money in the first few months due the to
          lack of knowledge, planning, and discipline
        </p>
      </div>
    </div>
  );
};
const Step3 = () => {
  return (
    <div className="text_inner">
      <h3 className="step_3h">
        We're here to help you build <br /> confidence through targeted <br />{" "}
        learning and hands-on practice!
      </h3>
      <img src={images["step_3.png"]} alt="step3" />
    </div>
  );
};

export default function Start() {
  const [step,setStep] = useState(1);
  const navigate  = useNavigate();
  const nextStep = ()=>{
    if(step+1===4){
      navigate("/onboarding/start/select-level")
    }
    setStep(step+1);
  }
  return (
    <div className="onboard_page">
    <div className="onboarding_container">
      <div className="inner">
    {
      step===1&&(<Step1/>)
    }
     {
      step===2&&(<Step2/>)
    }
     {
      step===3&&(<Step3/>)
    }

      </div>
      <div className="btn_box">
        <button className="btn" onClick={nextStep}>{
          step===1?("Get started"):("Continue")
          }</button>
      </div>
    </div>
    </div>
  );
}
