import React from 'react'

export default function loader1() {
  return (
    <div style={{ position: 'absolute', top: '40%', left: '50%' }}>
      {/* <div className="loader_spin" style={{ position: 'relative', left: '-50%' }}></div> */}
      <div className="ytp-spinner">
        <div>
          <div className="ytp-spinner-container">
            <div className="ytp-spinner-rotator">
              <div className="ytp-spinner-left">
                <div className="ytp-spinner-circle"></div>
              </div>
              <div className="ytp-spinner-right">
                <div className="ytp-spinner-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
