import React, { useEffect, useState } from "react";
import images from "../../../Assets/Images";
import Auth from "../../../helpers/Auth";
import { useNavigate, useParams } from "react-router-dom";
import PreLoader from "../../loader/PreLoader";
export default function InverstingPath() {
  const { http, user } = Auth();
  const navigate = useNavigate();
  const params = useParams();
  const [state, setState] = useState();
  const [onboardingCourse, setOnboardingCourse] = useState();
  useEffect(() => {
    http
      .get(`/onboarding-path-course/${params.onboarding_path_id}`)
      .then((res) => {
        // console.log(res.data.data);

        let beginner = [];
        let intermediate = [];
        let advanced = [];
        if (res.data.data) {
          res.data.data.courses.forEach((el) => {
            if (el.level === "beginner") {
              beginner.push(el);
            }
            if (el.level === "intermediate") {
              intermediate.push(el);
            }
            if (el.level === "advanced") {
              advanced.push(el);
            }
          });

          let x = [
            {
              level: "beginner",
              course: beginner,
            },
            {
              level: "intermediate",
              course: intermediate,
            },
            {
              level: "advanced",
              course: advanced,
            },
          ];
          setOnboardingCourse({
            id: res.data.data.id,
            image: res.data.data.image,
            name: res.data.data.name,
            description: res.data.data.description,
            component: x,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="onboard_page">
      {onboardingCourse ? (
        <>
          <div className="investing_path_header">
            <button onClick={() => navigate(`/learning`)}>
              <img src={images["right.svg"]} alt="" />
            </button>
            <img
              src={onboardingCourse && onboardingCourse.image}
              alt="path-1"
            />
          </div>
          <div
            className="investing_pathContainer"
            style={{ padding: "17px 0px" }}
          >
            <h2 className="investing_path_h">
              {onboardingCourse && onboardingCourse.name}
            </h2>
            <p className="investing_path_Subh">
              {onboardingCourse && onboardingCourse.description}
            </p>
          </div>
          {onboardingCourse.component.map((el, i) => {
            return (
              <div className="investing_pathContainer" key={i}>
                <h3 className="investing_topic">{el.level}</h3>

                <ul className="course_list">
                  {el.course.map((ej, j) => (
                    <li
                      key={i + j}
                      // att={ej.course_id}
                      onClick={() => navigate(`/courses/${ej.course_id}`)}
                      className={ej.complete===true?"complete":""}
                    >
                      <div className="svg_box">
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                          fill="white"
                          xmlns="http://www.w3.org/2000/svg"
                          className="AGmcg"
                        >
                          <path
                            id="Vector"
                            d="M17.3887 9.02403L14.0488 11.9059L15.0664 16.2158C15.1225 16.4498 15.108 16.6951 15.0248 16.9209C14.9416 17.1466 14.7933 17.3427 14.5987 17.4842C14.4042 17.6257 14.172 17.7064 13.9316 17.7161C13.6912 17.7257 13.4533 17.6639 13.248 17.5384L9.49995 15.2317L5.74968 17.5384C5.54441 17.6632 5.30682 17.7244 5.06682 17.7144C4.82682 17.7044 4.59515 17.6236 4.40098 17.4822C4.2068 17.3408 4.05881 17.1451 3.97563 16.9197C3.89246 16.6944 3.87781 16.4495 3.93355 16.2158L4.9548 11.9059L1.61495 9.02403C1.43334 8.86707 1.30199 8.66008 1.23732 8.42891C1.17264 8.19774 1.1775 7.95264 1.25129 7.72422C1.32508 7.49579 1.46454 7.29417 1.65223 7.14453C1.83993 6.99489 2.06755 6.90387 2.30667 6.88282L6.68558 6.52954L8.3748 2.44157C8.46623 2.21878 8.62185 2.02821 8.82187 1.89409C9.02189 1.75997 9.25727 1.68835 9.4981 1.68835C9.73892 1.68835 9.97431 1.75997 10.1743 1.89409C10.3743 2.02821 10.53 2.21878 10.6214 2.44157L12.3099 6.52954L16.6888 6.88282C16.9284 6.90309 17.1567 6.9936 17.345 7.14304C17.5334 7.29247 17.6735 7.49417 17.7478 7.72287C17.822 7.95157 17.8271 8.19709 17.7625 8.42869C17.6978 8.66028 17.5662 8.86763 17.3842 9.02477L17.3887 9.02403Z"
                          ></path>
                        </svg>
                      </div>
                      <div className="course_list_content">
                        <p>Course {j + 1}</p>
                        <h4>{ej.name}</h4>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </>
      ) : (
        <PreLoader />
      )}
    </div>
  );
}
