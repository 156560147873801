import React, { useState, useEffect, useRef } from "react";
import "intl-tel-input/build/css/intlTelInput.css";
import Auth from "../../helpers/Auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import PreLoader from "../loader/PreLoader";
import images from "../../Assets/Images";

const VerificationForm = ({setLeadStatus}) => {
  const inputRef = useRef(null); // Reference for the phone input
  const { http, user } = Auth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState({
    loader: false,
    response: false,
  });
  const [ip, setIP] = useState("");
  const naviagte = useNavigate();

  const [formData, setFormData] = useState({
    firstName: user.name,
    lastName: "",
    email: user.email,
    phone: "",
    isoCode: "US", // Default to 'US'
    callingCode: "1", // Default to '1'
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    // console.log(res.data);
    setIP(res.data.ip);
  };
  let click_id = searchParams.get("click_id");
  useEffect(() => {
    if (inputRef.current) {
      const iti = window.intlTelInput(inputRef.current, {
        initialCountry: "auto",
        separateDialCode: true,
        formatAsYouType: false,
        countryOrder: ["ca", "gb", "au", "nz"],
        geoIpLookup: (callback) => {
          fetch("https://ipapi.co/json")
            .then((res) => res.json())
            .then((data) => callback(data.country_code))
            .catch(() => callback("us"));
        },
        utilsScript:
          "https://cdn.jsdelivr.net/npm/intl-tel-input@23.8.1/build/js/utils.js",
      });

      inputRef.current.addEventListener("countrychange", () => {
        const countryData = iti.getSelectedCountryData();
        setFormData((prev) => ({
          ...prev,
          isoCode: countryData.iso2,
          callingCode: countryData.dialCode,
        }));
      });
      getData();
      return () => {
        iti.destroy();
      };
    }
  }, []);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Basic validation for the form
  const validateForm = () => {
    let valid = true;
    let validationErrors = {};

    if (!formData.firstName) {
      validationErrors.firstName = "Please enter your first name";
      valid = false;
    }

    if (!formData.lastName) {
      validationErrors.lastName = "Please enter your last name";
      valid = false;
    }

    if (!formData.email) {
      validationErrors.email = "Please enter your email";
      valid = false;
    }

    if (!formData.phone) {
      validationErrors.phone = "Please enter your phone number";
      valid = false;
    } else if (!/^\d+$/.test(formData.phone)) {
      validationErrors.phone = "Only numbers are allowed";
      valid = false;
    }

    setErrors(validationErrors);
    return valid;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
   
   
    if (validateForm()) {
      // Your form submission logic here

      setState({ ...state, loader: true });
      let data = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        country_code: formData.isoCode.toUpperCase(),
        email: formData.email,
        phone_dial_code: formData.callingCode,
        phone_number: formData.phone,
        ip_address: ip,
      };
      http
        .post(`/update-user-leadstatus/${user.id}`, data)
        .then((res) => {
          console.log(res.data);
          setLeadStatus((prev)=>{
            return true;
          })
          setState({
            ...state,
            loader: false,
            response: true,
            autologin_url: res.data.autologin_url,
          });
          if (click_id !== null) {
            naviagte("/");
          } else {
            naviagte("/learning");
          }
        })
        .catch((error) => {
          setState({ ...state, loader: false });
          // Enhanced error logging
          if (error.response) {
            if (error.response.data.message.status === "error") {
              setErrors({
                ...errors,
                // email: error.response.data.message.message,
                email: "This email already verified",
              });
            }
            console.error("API error response:", error.response.data);
          } else {
            console.error("Error:", error.message);
            if (click_id !== null) {
              naviagte("/");
            } else {
              naviagte("/learning");
            }
          }
          // console.log(error);
        });

      //   for (let entry of searchParams.entries()) {
      //     data[entry[0]] = entry[1];
      //   }

      // console.log(data); // [["page", 5], ["pageSize", 25]]
    }
  };
  const closeLeadForm = () => {
    console.log(click_id)
    if (click_id !== null) {
      naviagte("/");
    } else {
      naviagte("/learning");
    }
  };
  const ThanksPage = () => {
    return (
      <>
        {/* <div class="header">
          <button onClick={() => naviagte("/onboarding/start/learning")}>
            <img
              width={22}
              src={images["book_inner_pop_up_cross.png"]}
              alt="close"
            />
          </button>
        </div> */}
        <div className="inner" style={{ overflow: "hidden" }}>
          <div className="text_inner">
            <h3 className="text_h">You MUST Read this Now.</h3>
            {/* <h3 className="text_h" style={{color:"black"}}>Account Verification Required.</h3> */}

            <p className="text_p">
              This is the most important FINAL STEP to setting up your Account,{" "}
              <mark>so pay very close attention here</mark> as you need to make
              sure your system is setup correctly.
            </p>
            <h4 className="text_h4"> Phone Call Verification & Setup</h4>
            <p className="text_p">
              In this program (as you have seen) we take security very seriously
              and in-order to verify you are a real person and to validate your
              account, one of our <strong>start up specialists </strong> will be
              calling you within the next 30mins. (Mon-Fri).
            </p>
            <p className="text_p">
              If you aren't available, please answer the call and schedule an
              appointment for a more convenient time.
            </p>
            {/* <div className="read_more_btn">
              <button type="submit">GO TO ACCOUNT</button>
            </div> */}
            {/* <a
              target="_blank"
              rel="noopener noreferrer"
              //href={state.autologin_url}
              href="https://clickrdir.com/o/r97vy?lp=341"
              className="new_btn"
              style={{ color: "white" }}
            >
              GO TO ACCOUNT
            </a> */}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="onboard_page">
      <div className="onboarding_container">
        {state.loader === false ? (
          <>
            <div class="header">
              <button onClick={closeLeadForm}>
                <img
                  width={22}
                  src={images["book_inner_pop_up_cross.png"]}
                  alt="close"
                />
              </button>
              <h3>Account Verification Required</h3>
              <div></div>
            </div>
            <div className="chapter_box">
              {/* <div className="text_inner">
                    <h4 style={{ fontWeight: 500 }}>
                      Register your account via Finiva and receive a FREE
                      Personal Account Manager to walk you through your account
                      setup process.
                    </h4>
                  </div> */}
              <ThanksPage />

              <form
                onSubmit={handleSubmit}
                className="get_access_form submit_form"
                method="post"
                id="api-form"
              >
                <input
                  type="hidden"
                  name="iso_code"
                  id="iso_code"
                  value={formData.isoCode}
                />
                <input
                  type="hidden"
                  name="calling_code"
                  id="calling_code"
                  value={formData.callingCode}
                />

                <div className="my-3 mt-0">
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    id="first_name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                  {errors.firstName && (
                    <span className="error_text">*{errors.firstName}</span>
                  )}
                </div>

                <div className="my-3">
                  <label htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    id="last_name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                  {errors.lastName && (
                    <span className="error_text">*{errors.lastName}</span>
                  )}
                </div>

                <div className="my-3">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && (
                    <span className="error_text">*{errors.email}</span>
                  )}
                </div>

                <div className="my-3 intl_box">
                  <label htmlFor="mobile">Phone</label>
                  <input
                    ref={inputRef}
                    className="form-control number_field_style"
                    name="phone"
                    type="tel"
                    id="mobile"
                    value={formData.phone}
                    onChange={handleInputChange}
                    maxLength="10"
                  />
                  {errors.phone && (
                    <span className="error_text">*{errors.phone}</span>
                  )}
                  {/* <span className="required_text">*Required for verification</span> */}
                </div>

                <div className="read_more_btn">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <PreLoader />
        )}
      </div>
    </div>
  );
};

export default VerificationForm;
