import { configureStore } from "@reduxjs/toolkit";

import walletReducer from "./features/wallet/walletSlice";
import counterReducer from "./features/counter/counterSlice";
import popupReducer from "./features/popup/popup";



export const store = configureStore({
  reducer: {
    wallet: walletReducer,
    counter: counterReducer,
    popup:popupReducer
  },
});
