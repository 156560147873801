
import React, { useState, useEffect } from "react";
import Auth from "../../helpers/Auth";
import { Link, useLocation } from "react-router-dom";
import PreLoader from "../loader/PreLoader";
import Logo from "../component/Logo";


export default function Navbar() {
  const { http, setToken } = Auth();
  const location = useLocation();
  const [passwordView, setPasswordView] = useState(true);
  const [errors, setErrors] = useState({});
  const [inputField, setInputField] = useState({
    email: "",
    password: "",
  });
  const [login, setLogin] = useState({
    is_login: true,
    error: null,
  });

  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  function loginForm(e) {
    e.preventDefault();
    const newErrors = validateForm(inputField);
    setErrors(newErrors);
    // console.log(inputField)
    if (Object.keys(newErrors).length === 0) {
      setLogin({ ...login, is_login: false });
      http
        .post("/user-login", {
          email: inputField.email,
          password: inputField.password,
        })
        .then((res) => {
          // console.log(res.data);

          setToken(
            {
              id: res.data.data.id,
              name: res.data.data.name,
              email: res.data.data.email,
              isplanActive: res.data.data.isplanActive,
              accesChatGPT: res.data.data.accesChatGPT
            },
            res.data.data.accessToken
          );
        })
        .catch((err) => {
          // console.log(err.response);
          setLogin({
            ...login,
            is_login: true,
            error: err.response.data.message,
          });
        });
    } else {
      console.log(`Form submission failed due to validation errors.`);
    }
  }

  const validateForm = (data) => {
    const errors = {};

    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }
    if (!data.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const viewPassword = () => {
    if (passwordView) {
      setPasswordView(false);
    } else {
      setPasswordView(true);
    }
  };


  return (
    <div className="container">
    {login.is_login ? (
      <>
        <Logo />
        <div className="login_box">
          <h2>Welcome Back</h2>
          <form className="login_form" onSubmit={loginForm}>
            <div className="input_box">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                onChange={inputsHandler}
                value={inputField.email}
                placeholder="youremail@domain.com"
              />
              {errors.email && (
                <p className="error_text">{"*" + errors.email}</p>
              )}
            </div>

            <div className="input_box">
              <label htmlFor="password">Password</label>
              <input
                type={passwordView ? "password" : "text"}
                name="password"
                onChange={inputsHandler}
                value={inputField.password}
                placeholder="Enter your password"
              />
              {errors.password && (
                <p className="error_text">{"*" + errors.password}</p>
              )}
              {login.error && (
                <p className="error_text">{"*" + login.error}</p>
              )}
              <i
                className={
                  passwordView ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"
                }

                onClick={viewPassword}
              ></i>
            </div>

            <Link to={"/auth/forgot"} className="forget_password_text">
              Forgot password?
            </Link>
            <input
              type="submit"
              value="Sign In"
              className="btn Sign_inbtn"
            // disabled = {!login.is_login}
            />
          </form>
          <p className="login_subtext">
            Don't have an account?{" "}
            <a href="https://finiva.io">Start the quiz</a>
             {/* <Link to="/auth/signup">Start the quiz</Link> */}
          </p>
        </div>
      </>
    ) : (
      <PreLoader />
    )}
  </div>
  )
}
