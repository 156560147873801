import React from 'react'

export default function ChatLoader() {
    return (
        <div className="chat_loading">
            <div className="chat_loading--dot"></div>
            <div className="chat_loading--dot"></div>
            <div className="chat_loading--dot"></div>
        </div>
    )
}
