import React, { useEffect, useState } from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import images from "../../../Assets/Images";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../../helpers/Auth";
// import Skeleton from "../../component/Skeleton";
// import { fetchWalletBalance } from "../../../redux/features/wallet/walletSlice";
import {  useSelector } from "react-redux";
import DailyPopup from "../../component/DailyPopup";
// import SimulatorPopUp from "../../component/SimulatorPopUp";

export default function Simulator() {
  const navigate = useNavigate();
  const { http, user } = Auth();
  const [state, setState] = useState({
    profit_loss: 0,
    total_invest: 1,
  });

  const [popUpState, setPopUpState] = useState({
    guide: true,
    hide: false,
  });

  const [showTicker, setShowTicker] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const balance = useSelector((state) => state.wallet.balance);
  useEffect(() => {
    http
      .get(`/get-active-asset-crypto/${user.id}`)
      .then((res) => {
        // console.log(res);
        if (
          Array.isArray(res.data.data.activeTrade) &&
          res.data.data.activeTrade.length > 0
        ) {
          let x = [];
          let y = 0;
          res.data.data.activeTrade.forEach((el) => {
            el.latest_price = el.close_price;
            x.push(el);
            y += el.total_value;
          });

          // console.log(x)
          setShowTicker(x);

          setState({ ...state, total_invest: y });
        }
        setPopUpState({ ...popUpState, guide: res.data.data.guide });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // let timeoutId;
    let isMounted = true;
    if (showTicker === undefined) return;

    const fetchPostData = async () => {
      try {
        const res = await http
          .post("/latest-trade-price-bulk", { data: showTicker })
          .then((res) => {
            // console.log(res.data.data);
            setShowTicker(res.data.data);
            let profit_loss = 0;
            res.data.data.forEach((el) => {
              if (el.latest_price === 0) {
                el.latest_price = el.close_price;
              }
              profit_loss += parseFloat(
                (el.latest_price - el.avg_place_price) * el.avg_left_qty
              );
            });

            setState({ ...state, profit_loss: profit_loss });
            setDataLoading(false);
          })
          .catch((err) => {
            setDataLoading(false);
          });
      } catch (error) {
        if (isMounted) {
          setDataLoading(false);
          console.error(error);
        }
      }
    };

    const interval = setInterval(fetchPostData, 5000); // Poll every 10 seconds

    return () => {
      clearInterval(interval);
      isMounted = false;
    };
  }, [showTicker]);

  const hidePopoUp = async () => {
    let x = await http.post(`/user-update-popup-status/${user.id}`);
    setPopUpState({ ...popUpState, hide: true, guide: true });
    setTimeout(() => {
      setPopUpState({ ...popUpState, hide: false, guide: true });
    }, 3000);
  };
  // const activePopUp = () => {
  //   setPopUpState({ ...popUpState, hide: false });
  // };

  return (
    <>
      <div className="main_container">
        <Header />

        {popUpState.guide ? (
          <div className="chapter_box simulator_box">
            <div className="account_value_box">
              <ul>
                <li>Account value</li>
                <li>
                  <p className="demo_tag">DEMO</p>
                  <img src={images["question_mark.svg"]} alt="question" />
                </li>
              </ul>
              <div className="value_info_box">
                <h3>
                  $<span>{(balance + state.profit_loss).toFixed(2)}</span>
                </h3>
              </div>
              <div className="metrics_box">
                <div className="return_profit_box">
                  <h5>Total return</h5>
                  <p
                    style={{
                      color:
                        state.profit_loss > 0
                          ? "rgb(77, 224, 114)"
                          : "rgb(212, 51, 51)",
                    }}
                  >
                    {((state.profit_loss * 100) / state.total_invest).toFixed(
                      2
                    ) + "%"}
                  </p>
                </div>
                <div className="return_profit_box">
                  <h5>Total profit/loss</h5>

                  <p
                    style={{
                      color:
                        state.profit_loss > 0
                          ? "rgb(77, 224, 114)"
                          : "rgb(212, 51, 51)",
                    }}
                  >
                    {state.profit_loss > 0
                      ? ` ${"+$" + state.profit_loss.toFixed(2)}`
                      : `${"-$" + (state.profit_loss * -1).toFixed(2)}`}
                  </p>
                </div>
              </div>
              <div className="btn_group trade_now_btn">
                <button
                  className="btn_theme_1"
                  onClick={() => navigate("/simulator/buy-assets")}
                >
                  {/* <!-- add_remove_box --> */}
                  <div
                    className="toltip_box"
                    style={{
                      display: popUpState.hide === true ? "block" : "none",
                    }}
                  >
                    <h3>Let's make your first trade</h3>
                    <p>
                      Master investment skills by <br /> training trades every
                      day
                    </p>
                  </div>
                  {/* <!-- add_remove_box --> */}
                  <img src={images["plus.svg"]} alt="plus" />
                  <span>TRADE NOW</span>
                </button>
              </div>
            </div>
            <div className="active_trads_box">
              <div className="active_trad_titleBar">
                <p>Active Trades</p>
                <span>{showTicker && showTicker.length} items</span>
              </div>
            </div>

            <div className="asset_container">
              {/* {showTicker ? ( */}
                <ul>
                  {showTicker&&showTicker.length > 0 ? (
                    showTicker.map((el, index) => {
                      if (el.latest_price === 0) {
                        el.latest_price = el.close_price;
                      }
                      return (
                        <li key={index}>
                          <Link
                            className=""
                            to={`/simulator/buy-assets/${el.ticker_name}`}
                          >
                            <div className="info_box">
                              <div className="img_box">
                                <img src={el.image} alt="company_logo" />
                              </div>
                              <div className="content_box">
                                <p className="name">{el.name}</p>
                                <p className="ticker">
                                  {el.close_price + " " + el.ticker_name}
                                </p>
                              </div>
                            </div>
                            <div className="costs_box">
                              <p className="cost">${el.total_value.toFixed(2)}</p>
                              {el.latest_price - el.avg_place_price > 0 ? (
                                <p className="pnl blue">
                                  {(
                                    (el.latest_price - el.avg_place_price) *
                                    el.avg_left_qty
                                  ).toFixed(2)}
                                </p>
                              ) : (
                                <p className="pnl red">
                                  {(
                                    (el.latest_price - el.avg_place_price) *
                                    el.avg_left_qty
                                  ).toFixed(2)}
                                </p>
                              )}
                            </div>
                          </Link>
                        </li>
                      );
                    })
                  ) : (
                    <li style={{ textAlign: "center" }}>
                      No active trades yet
                    </li>
                  )}
                </ul>
              {/* ) : (
                <Skeleton />
              )} */}
            </div>
          </div>
        ) : (
          <div
            className="onboard_page"
            style={{ display: popUpState.hide === true ? "none" : "" }}
          >
            <div className="onboarding_container">
              <div className="inner">
                <img
                  src={images["start.svg"]}
                  className="width-100"
                  alt="start"
                />

                <div className="text_inner">
                  <div className="welcome_text_box">
                    <p>Welcome to</p>
                    <img src={images["logo.png"]} alt="logo" />
                  </div>
                  <h2 className="h-1">
                    Your investment journey starts <br /> here
                  </h2>
                </div>
              </div>
              <div className="btn_box">
                <button className="btn">Get started</button>
              </div>
            </div>
            {/* <!-- only_add_className==="upsell_popup_active" --> */}
            <div className="upsell_popup upsell_popup_active" id="Did_you_know">
              <div className="popup_inner">
                <h3>
                  Let's get to know how you will <br /> practice
                </h3>

                <div className="img_box">
                  <img src={images["popup-1.png"]} alt="popup" />
                </div>

                <button className="btn" onClick={hidePopoUp}>
                  SHOW ME HOW TO DO IT
                </button>
                <button className="btn sub_btn" onClick={hidePopoUp}>
                  I'LL FIGURE OUT MYSELF
                </button>
              </div>
            </div>
            {/* <!-- only_add_className==="upsell_popup_active" --> */}
          </div>
        )}
      </div>

      <DailyPopup />
      {popUpState.guide && <Footer />}
    </>
  );
}
