import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Images from "../../Assets/Images";

export default function Footer() {
  const { pathname } = useLocation();
  const [active, setActive] = useState({
    active_home: false,
  });

  useEffect(() => {
    if (["/courses", "/challenges", "/books"].includes(pathname)) {
      setActive({ ...active, active_home: true });
    }
  }, [pathname]);
  return (
    <div className="footer_nav">
      <ul>
        <li>
          <NavLink to={"/"} className={active.active_home ? "active" : ""}>
            <div className="img-box">
              <img src={Images["home.png"]} alt="icon1" />
            </div>
            <div className="content_box">
              <p>Home</p>
            </div>
          </NavLink>
        </li>

        <li>
          <NavLink to={"/chat"}>
            <div className="img-box">
              <img src={Images["chat.png"]} width={25} alt="icon1" />
            </div>
            <div className="content_box">
              <p>Chat</p>
            </div>
          </NavLink>
        </li>

        <li>
          <NavLink to="/simulator">
            <div className="img-box">
              <img src={Images["Simulator.png"]} alt="icon1" />
            </div>
            <div className="content_box">
              <p>Simulator</p>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/leaderboard">
            <div className="img-box">
              <img src={Images["Rating.png"]} alt="icon1" />
            </div>
            <div className="content_box">
              <p>Rating</p>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/profile">
            <div className="img-box">
              <img src={Images["profile.png"]} alt="icon1" />
            </div>
            <div className="content_box">
              <p>Profile</p>
            </div>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
