import React from "react";
import images from "../../Assets/Images";

export default function Logo() {
  return (
    //   <div className="top_header">
    <div className="logo_box">
      <img src={images["logo.png"]} className="img_fluid" alt="" />
    </div>
    // </div>
  );
}
