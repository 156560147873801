import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../../helpers/Auth";
import PreLoader from "../../loader/PreLoader";
import Loader from "../../loader/Loader1";
export default function SubscriptionPlan() {
  const navigate = useNavigate();
  const [userSubscriptionPlan, setUserSubscriptionPlan] = useState([]);
  const [state, setState] = useState({
    modal: false,
    loader: false,
    isData: false,
    error: false,
  });

  const { http, user, errorHandle } = Auth();

  useEffect(() => {
    // console.log(user)
    http
      .get(`/user-subscription-plan/${user.id}`)
      .then((res) => {
        // console.log(res.data.data)
        setState({ ...state, isData: true });
        setUserSubscriptionPlan(res.data.data);
      })
      .catch((err) => {
        errorHandle(err);
        // console.log(err.message);
      });
  }, []);

  const cancelSubscription = () => {
    setState({ ...state, loader: true });
    http
      .post(`/user/cancel-subscription`, {
        customer_subscription_details_id:
          userSubscriptionPlan[0].customer_subscription_details_id,
        subscription_Id: userSubscriptionPlan[0].stripe_subscription_id,
        gw_mode: userSubscriptionPlan[0].is_test_data,
        email: user.email,
      })
      .then((res) => {
        console.log(res.data);
        setState({
          ...state,
          modal: true,
          loader: false,
          message: res.data.data.message,
        });
      })
      .catch((err) => {
        console.log(err.response.data);
        setState({
          ...state,
          modal: true,
          loader: false,
          message: err.response.data.message,
        });
      });
    // console.log('ok')
  };

  const reactiveSubscription = () => {
    setState({ ...state, loader: true });
    http
      .post(`/user/reactive-subscription`, {
        customer_subscription_details_id:
          userSubscriptionPlan[0].customer_subscription_details_id,
        subscription_Id: userSubscriptionPlan[0].stripe_subscription_id,
        gw_mode: userSubscriptionPlan[0].is_test_data,
        email: user.email,
      })
      .then((res) => {
        console.log(res.data);

        setState({
          ...state,
          modal: true,
          loader: false,
          message: res.data.data.message,
          response_msg: res.data.message, //error,success
        });
      })
      .catch((err) => {
        console.log(err.response.data);
        setState({
          ...state,
          modal: true,
          loader: false,
          message: err.response.data.message,
          response_msg: "error",
        });
      });
  };

  const closeModal = () => {
    setState({ ...state, modal: false });

    window.location.reload();
  };
  const paymentCurrency = (currency) => {
    let curr = currency.toLowerCase();
    if (curr === "usd") {
      return "$";
    } else if (curr === "eur") {
      return "€";
    } else if (curr === "gbp") {
      return "£";
    } else {
      return "$";
    }
  };
  return (
    <>
      {state.isData ? (
        <>
          {userSubscriptionPlan && userSubscriptionPlan.length > 0 ? (
            <div className="container" style={{ padding: "0px" }}>
              <div className="settings_section">
                <div className="container_large  padding-0">
                  <div className="profile_header">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/profile/settings");
                      }}
                    >
                      <div style={{ color: "white" }}>
                        <i className="fa fa-angle-left"></i>
                      </div>
                    </Link>
                    <p>Subscription</p>
                    <div></div>
                  </div>
                </div>
                <div className="profile_body">
                  <div className="subcription_body">
                    <h4>My Plan</h4>
                    <div className="subcription_dtl_box">
                      <div className="subcription_header">
                        <h3>Finiva subscription</h3>
                      </div>
                      <div className="subcription_body_content">
                        <ul>
                          <li>
                            <p>Plan name:</p>
                            <h5>{userSubscriptionPlan[0].plan_name}</h5>
                          </li>
                          <li>
                            <p>Billing period:</p>
                            <h5>
                              {userSubscriptionPlan[0].duration_days + "-days"}
                            </h5>
                          </li>

                          {/* {userSubscriptionPlan[0].is_onetime_purchase === 0 ? (
                            <>
                              {" "}
                              {userSubscriptionPlan[0].payment_total > 0 && (
                                <li>
                                  <p>Subscription price:</p>
                                  <h5>
                                    {paymentCurrency(
                                      userSubscriptionPlan[0].payment_currency
                                    ) + userSubscriptionPlan[0].payment_total}
                                  </h5>
                                </li>
                              )}
                              {userSubscriptionPlan[0].payment_total > 0 && (
                                <li>
                                  <p>
                                    <strong>Subscription total:</strong>
                                  </p>
                                  <h5>
                                    {paymentCurrency(
                                      userSubscriptionPlan[0].payment_currency
                                    ) + userSubscriptionPlan[0].payment_total}
                                  </h5>
                                </li>
                              )}
                            </>
                          ) : (
                            <>
                              {" "}
                              <li>
                                <p>
                                  {" "}
                                  <strong>Total price:</strong>
                                </p>
                                <h5>
                                  {paymentCurrency(
                                    userSubscriptionPlan[0].payment_currency
                                  ) + userSubscriptionPlan[0].payment_total}
                                </h5>
                              </li>
                            </>
                          )} */}
                        </ul>

                        {/* you will be billed amount / 12 weeks from date */}
                        {userSubscriptionPlan[0].is_canceled === 0 && (
                          <h5>
                            You will be billed{" "}
                            {paymentCurrency(
                              userSubscriptionPlan[0].payment_currency
                            ) + userSubscriptionPlan[0].recurring_price}{" "}
                            /{" "}
                            {Math.round(
                              userSubscriptionPlan[0].duration_days / 7
                            ) > 1
                              ? Math.round(
                                  userSubscriptionPlan[0].duration_days / 7
                                )
                              : 4}{" "}
                            weeks from{" "}
                            {userSubscriptionPlan[0].subscription_end_date}
                          </h5>
                        )}

                        {/* {userSubscriptionPlan[0].is_onetime_purchase === 1 && (
                          <h5>
                            Your plan valid till
                            {" " +
                              userSubscriptionPlan[0].subscription_end_date}
                          </h5>
                        )} */}
                      </div>
                    </div>
                    {userSubscriptionPlan[0].is_canceled === 0 && (
                      <button
                        className="btn reactivate_btn"
                        onClick={cancelSubscription}
                      >
                        Cancel Subscription
                      </button>
                    )}
                    {userSubscriptionPlan[0].is_canceled === 1 && (
                      <>
                        <div
                          className="subcription_footer"
                          style={{ marginTop: "17px" }}
                        >
                          <p>
                            We have cancelled your subscription and you will not
                            be charged in the future
                          </p>
                          <p>
                            You still have access to everything Finiva until
                            your current subscription ends
                          </p>
                        </div>
                        <div>
                          <button
                            className="btn reactivate_btn"
                            onClick={reactiveSubscription}
                          >
                            Reactivate Subscription
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {state.modal === true && (
                <div
                  className="email_codCheck_poup"
                  onTouchStart={() => console.log("test")}
                  style={{ display: state.modal ? "block" : "none" }}
                >
                  <div className="email_codCheck_box">
                    <div className="Closeicone_box">
                      <i className="fa fa-close" onClick={closeModal}></i>
                    </div>

                    {/* <p>{state.message}</p> */}
                    <p>
                      We couldn't reactivate your subscription. Please purchase
                      a new one by clicking the "<strong>Buy Now</strong>" button.
                    </p>
                    <div className="code_input_box">
                      {state.response_msg === "error" && (
                        <a
                          href={
                            `https://finiva.io/reactivate/?rt=` +
                            btoa(
                              userSubscriptionPlan[0].stripe_subscription_id +
                                "|" +
                                userSubscriptionPlan[0].is_test_data +
                                "|" +
                                user.email
                            )
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn reactivate_btn"
                          style={{ display: "block" }}
                        >
                          Buy Now
                        </a>
                      )}
                      {state.response_msg === "success" && (
                        <input
                          type="submit"
                          value="Ok"
                          className="btn Sign_inbtn"
                          onClick={closeModal}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {state.loader === true && <Loader />}
            </div>
          ) : (
            <div className="container" style={{ padding: "0px" }}>
              <div className="settings_section">
                <div className="container_large  padding-0">
                  <div className="profile_header">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/profile/settings");
                      }}
                    >
                      <div style={{ color: "#fff" }}>
                        <i className="fa fa-angle-left"></i>
                      </div>
                    </Link>
                    <p>Subscription</p>
                    <div></div>
                  </div>
                </div>
                <div className="profile_body" style={{ marginTop: "88px" }}>
                  <div className="subcription_body">
                    <h3>You have no any plan</h3>
                    <div className="subcription_body_content">
                      <Link
                        to={"#"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn reactivate_btn"
                        style={{ display: "block" }}
                      >
                        Activate plan
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <PreLoader />
      )}
    </>
  );
}
