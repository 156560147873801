// counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    dailyPopup: (state,action) => {
      state.value = action.payload;
    },
    
  },
});

// Export actions
export const { dailyPopup } = popupSlice.actions;

// Export the reducer
export default popupSlice.reducer;
