import React, { useEffect, useState } from "react";
import images from "../../../Assets/Images";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Auth from "../../../helpers/Auth";
import PreLoader from "../../loader/PreLoader";

export default function Learning() {
  const location = useLocation();
  const navigate = useNavigate();
  const { http, user } = Auth();
  const [state, setState] = useState({
    level: false,
    mini: false,
  });
  const [onboardingPath, setOnboardingPath] = useState();
  const [miniCourse, setMiniCourse] = useState();
  useEffect(() => {
    if (location.state) {
      // {level: 'Entry'}
      setState({ ...state, level: true });
    }

    http
      .get(`/onboarding-learning-path`)
      .then((res) => {
        // console.log(res.data.data);
        setOnboardingPath(res.data.data.onboarding);
        setMiniCourse(res.data.data.mini_course);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const activeTab = () => {};

  return (
    <div className="onboard_page">
      {onboardingPath ? (
        <>
          <div className="header">
            {state.level ? (
              <button
                onClick={() => navigate("/onboarding/start/select-level")}
              >
                <img src={images["right.svg"]} alt="right" />
              </button>
            ) : (
              <button onClick={() => navigate("/courses")}>
                <img src={images["close.svg"]} alt="right" />
              </button>
            )}
          </div>
          <div className="learning_path_container">
            <h3 className="pick_h">Learning paths</h3>

            <ul className="learning_paths_list">
              <li
                className={state.mini === false ? "active" : ""}
                onClick={() => setState({ ...state, mini: false })}
              >
                Main Paths
              </li>
              <li
                className={state.mini === true ? "active" : ""}
                onClick={() => setState({ ...state, mini: true })}
              >
                Mini courses
              </li>
            </ul>
            {state.mini === true && (
              <>
                {miniCourse &&
                  miniCourse.map((el,i) =>( 
                    <div key={el.id} style={{cursor:"pointer"}} className="mini_course_box" onClick={()=>navigate(`/courses/${el.id}`)}>
                      <div className="img_box">
                        <img src={images[`mini-${i+1}.png`]} alt="mini" />
                      </div>
                      <div className="text_box">
                        <h3>
                         {el.name}
                        </h3>
                      </div>
                    </div>
                  ))}
              </>
            )}
            {state.mini === false && (
              <>
                <h3 className="pick_h">Pick your path</h3>
                <p className="pick_text">
                  Based on your results here are journeys we recommend. You can
                  change journey any time later
                </p>
                {onboardingPath.map((el) => {
                  return (
                    <div className="path_box" key={el.id}>
                      <Link to={`/learning/${el.id}`}>
                        <div className="img_box">
                          <img src={el.image} alt="path" />
                          <p>
                            View full path{" "}
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#5653FE"
                              xmlns="http://www.w3.org/2000/svg"
                              className="ml-2"
                            >
                              <g id="icon">
                                <path
                                  id="Vector"
                                  d="M15 4.5H19.5V9"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  id="Vector_2"
                                  d="M14.25 9.75L19.5 4.5"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  id="Vector_3"
                                  d="M9 19.5H4.5V15"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  id="Vector_4"
                                  d="M9.75 14.25L4.5 19.5"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  id="Vector_5"
                                  d="M19.5 15V19.5H15"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  id="Vector_6"
                                  d="M14.25 14.25L19.5 19.5"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  id="Vector_7"
                                  d="M4.5 9V4.5H9"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  id="Vector_8"
                                  d="M9.75 9.75L4.5 4.5"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </g>
                            </svg>
                          </p>
                        </div>
                      </Link>
                      <div className="text_box">
                        <h5>{el.name}</h5>
                        <div className="progres_inner">
                          <p>
                            {el.complete_course} / {el.total_course} Courses
                          </p>
                          <div className="progres_bar">
                            <div
                              className="progres_line"
                              style={{
                                width:
                                  el.complete_course > 0
                                    ? (el.complete_course * 100) /
                                      el.total_course
                                    : 0,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      ) : (
        <PreLoader />
      )}
    </div>
  );
}
