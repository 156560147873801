import React, { useEffect, useState } from "react";
import images from "../../../Assets/Images";
import Footer from "../../component/Footer";
import Auth from "../../../helpers/Auth";
import Skeleton from "../../component/Skeleton";
import VerifyAccount from "../../component/VerifyAccount";

export default function Leaderboard({ leadStatus }) {
  const { http, user } = Auth();
  const [leagues, setLeagues] = useState();
  const [usersCoin, setUsersCoin] = useState();
  const [state, setState] = useState({
    leageDescription: "",
    league_name: "",
  });
  const [isAboutPopupVisible, setIsAboutPopupVisible] = useState(false);
  const [isQuestionPopupVisible, setIsQuestionPopupVisible] = useState(false);
  const [isAboutPopupActive, setIsAboutPopupActive] = useState(false);
  const [isQuestionPopupActive, setIsQuestionPopupActive] = useState(false);
  useEffect(() => {
    http
      .get(`/league/${user.id}`)
      .then((res) => {
        // console.log(res.data.data);
        setLeagues(res.data.data.league);
        setUsersCoin(res.data.data.users_coin);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // Show and activate the "About" popup with delay
  const showAboutPopup = (el) => {
    setState({
      ...state,
      leageDescription: el.description,
      league_name: el.name,
    });
    setIsAboutPopupVisible(true);
    setTimeout(() => {
      setIsAboutPopupActive(true);
    }, 100);
  };

  // Hide and deactivate the "About" popup with delay
  const hideAboutPopup = () => {
    setIsAboutPopupActive(false);
    setTimeout(() => {
      setIsAboutPopupVisible(false);
    }, 100);
  };

  // Show and activate the "Question" popup with delay
  const showQuestionPopup = () => {
    setIsQuestionPopupVisible(true);
    setTimeout(() => {
      setIsQuestionPopupActive(true);
    }, 100);
  };

  // Hide and deactivate the "Question" popup with delay
  const hideQuestionPopup = () => {
    setIsQuestionPopupActive(false);
    setTimeout(() => {
      setIsQuestionPopupVisible(false);
    }, 100);
  };
  return (
    <>
      <div className="main_container">
        <div className="top_header">
          <div></div>
          <div className="book_header_text">
            <p>Leaderboard</p>
          </div>
          <div className="coin_store" onClick={showQuestionPopup}>
            <img
              src={images["question_mark.svg"]}
              alt="question"
              id="question"
            />
          </div>
        </div>
        <div className="leaderboard_header">
          <div className="announce_container">
            {leagues &&
              leagues.map((el) => {
                return (
                  <div
                    key={el.id}
                    className="announce_imgBox"
                    onClick={() => showAboutPopup(el)}
                  >
                    {el.user_complete_leaderboards.length > 0 ? (
                      <img src={el.image} alt="passed" />
                    ) : (
                      <img src={images["download.svg"]} alt="passed" />
                    )}
                  </div>
                );
              })}
          </div>
          <div className="announce_text">
            <h2>Apprentice Traders League</h2>
            {/* <p>Top 10 advance to the next league</p> */}
            {/* <div className="announce_scoreBox">
              <p>
                Challenge ends in: <span>7 hours</span>
              </p>
            </div> */}
          </div>
        </div>
        <div className="announceList_container">
          {usersCoin ? (
            usersCoin.map((el, index) => {
              let i = index + 1;
              let r = "rank3.svg";
              if (i === 1) {
                r = "rank1.svg";
              } else if (i === 2) {
                r = "rank2.svg";
              }
              return (
                <div className="announceList_box" key={i}>
                  <div className="left">
                    <div className="rank">
                      {i < 4 && <img src={images[r]} alt="rank1" />}
                    </div>
                    <div className="profile_img">
                      <img src={images["7.png"]} alt="" />{" "}
                    </div>
                    <div className="profile_name">
                      <p>{el.username}</p>
                    </div>
                  </div>
                  <div className="right">
                    <p>${el.coin_balance}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <Skeleton />
          )}
        </div>
      </div>

      {isQuestionPopupVisible && (
        <div
          className="popup_leaderBoard"
          id="question_popup"
          style={{ display: "block" }}
        >
          <div
            className={`popupContent_box ${
              isQuestionPopupActive ? "active" : ""
            }`}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <img src={images["help_bottomsheet.png"]} alt="help_bottomsheet" />
            <div className="popup_text">
              <h3>What is a leaderboard?</h3>
              <p>
                Leaderboard enables users to compare progress, track
                improvement, and compete for top positions. Complete courses and
                excel on the simulator to climb the leaderboard and advance in
                the leagues.
              </p>
              <div className="read_more_btn">
                <button type="button" onClick={hideQuestionPopup}>
                  GOT IT
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isAboutPopupVisible && (
        <div
          className="popup_leaderBoard"
          id="about_popup"
          style={{ display: "block" }}
        >
          <div
            className={`popupContent_box ${isAboutPopupActive ? "active" : ""}`}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <div className="popup_text">
              <h3>{state.league_name}</h3>
              <p>{state.leageDescription}</p>
              <div className="read_more_btn">
                <button type="button" onClick={hideAboutPopup}>
                  GOT IT
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
      {!leadStatus && <VerifyAccount />}
    </>
  );
}
