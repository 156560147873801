import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import images from "../../Assets/Images";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletBalance } from "../../redux/features/wallet/walletSlice";
import Auth from "../../helpers/Auth";

export default function FinishLesson() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = Auth();
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.wallet.balance);
  // const status = useSelector((state) => state.wallet.status);
  // const error = useSelector((state) => state.wallet.error);

  useEffect(() => {
    dispatch(fetchWalletBalance(user.id));
    // console.log(balance);
  }, [dispatch, user.id]);

  const toContinue = () => {
    if (params.course_id) {
      navigate(`/courses/${params.course_id}`);
    }
    if (params.challenge_id) {
      navigate(`/challenges/${params.challenge_id}`);
    }
  };
  return (
    <div class="read_chapter_container">
      <div class="finish_lesson_header">
        <div class="close_box" onClick={toContinue}>
          <img src={images["close.svg"]} alt="" />
        </div>
        <div class="coin_store">
          <ul>
            <li>
              <img src={images["coin.svg"]} alt="coin" />
            </li>
            <li>
              $<span>{balance}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="finish_lesson_inner">
        <div></div>
        <div class="finish_lesson_content">
          <img src={images["complete.png"]} alt="complete" />
          <h2>complete! </h2>
          <p>Keep up the practice to maintain your progress!</p>
        </div>
        <div class="continue_btnBox">
          <button class="btn_theme_1" onClick={toContinue}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
