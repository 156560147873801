import React, { useState, useEffect } from "react";
import images from "../../../Assets/Images";
import Auth from "../../../helpers/Auth";
import { useNavigate, useParams } from "react-router-dom";

export default function ChallengesTasks() {
  const { http, user } = Auth();
  const params = useParams();
  const navigate = useNavigate();
  const [challengeContent, setChallengeContent] = useState();
  const [dayChallengeContent, setDayChallengeContent] = useState([]);
  const [state, setState] = useState({
    step: 0,
    progres_bar: 0,
    dailyChallengeContentLength: 0,
    lesson_step: true,
    response: false,
    isUpDown: "up",
    isTrueFalse: "true",
    isBuySell: "sell",
  });

  useEffect(() => {
    http
      .get(`/daily-challenge-content/${params.task_id}`)
      .then((res) => {
        console.log(res);
        setDayChallengeContent(res.data.data.daily_challenge_contents);
        setState({
          ...state,
          dailyChallengeContentLength:
            res.data.data.daily_challenge_contents.length,
          progres_bar: 100 / res.data.data.daily_challenge_contents.length,
          fraction: 100 / res.data.data.daily_challenge_contents.length,
          order_no: res.data.data.day,
        });
        setChallengeContent(res.data.data.daily_challenge_contents[state.step]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const continueChallengeContent = () => {
    if (state.step < state.dailyChallengeContentLength - 1) {
      let challenge_complete = false;
      if (state.step + 1 === state.dailyChallengeContentLength - 1) {
        challenge_complete = true;
      }

      setChallengeContent(dayChallengeContent[state.step + 1]);
      setState({
        ...state,
        step: state.step + 1,
        progres_bar: state.progres_bar + state.fraction,
        challenge_complete: challenge_complete,
        response: false,
      });
    }
  };

  const finishChallenge =  () => {
    const result =  http.post(`/challenges-finish`, {
      user_id: user.id,
      challenge_id: params.challenge_id,
      daily_challenge_id: params.task_id,
      current_challenge_day: state.order_no,
    });
    navigate(
      `/challenges/${params.challenge_id}/tasks/${params.task_id}/complete`
    );
  };
  const myResponse = (res) => {
    setState({ ...state, response: true, answer: res });
  };
  return (
    <div className="read_chapter_container">
      <div className="read_chapter_topBar">
        <div
          className="close"
          onClick={() => navigate(`/challenges/${params.challenge_id}`)}
        >
          <img src={images["close.svg"]} alt="class" />
        </div>
        <div className="bars_track">
          <div
            className="bars_line"
            style={{ width: Math.round(state.progres_bar) + "%" }}
          ></div>
        </div>
        <div class="number_of_task">
          <p>
            <span>{state.step + 1}</span>/{state.dailyChallengeContentLength}
          </p>
        </div>
      </div>

      <div className="read_chapter_inner">
        <div className="read_chapter_contentBox" id="content_box">
          <div className="read_chapter_item">
            <div className="content_box">
              {challengeContent &&
                (state.response ? (
                  <>
                    {challengeContent.answer_image && (
                      <div className="img_box">
                        <img
                          src={challengeContent.answer_image}
                          alt="chapter"
                        />
                      </div>
                    )}

                    <div style={{ display: "flex", alignItems: "center" }}>
                      {challengeContent.correct_choice === state.answer ? (
                        <>
                          <img
                            src={images["correct.svg"]}
                            style={{
                              backgroundColor: "green",
                              borderRadius: "50%",
                              padding: "2px",
                              maxWidth: "18px",
                              marginRight: "7px",
                            }}
                            alt="correct"
                          />
                          <h3 className="title" style={{ fontSize: "18px" }}>
                            That's right
                          </h3>
                        </>
                      ) : (
                        <>
                          <img
                            src={images["incorrect.svg"]}
                            style={{
                              backgroundColor: "red",
                              borderRadius: "50%",
                              padding: "2px",
                              maxWidth: "18px",
                              marginRight: "7px",
                            }}
                            alt="incorrect"
                          />
                          <h3 className="title" style={{ fontSize: "18px" }}>
                            Incorrect
                          </h3>
                        </>
                      )}
                    </div>

                    {challengeContent.explanation && (
                      <p className="text">{challengeContent.explanation}</p>
                    )}
                  </>
                ) : (
                  <>
                    {challengeContent.question_image && (
                      <div className="img_box">
                        <img
                          src={challengeContent.question_image}
                          alt="chapter"
                        />
                      </div>
                    )}
                    {challengeContent.title && (
                      <h3 className="title" style={{ fontSize: "18px" }}>
                        {challengeContent.title}
                      </h3>
                    )}
                    {challengeContent.description && (
                      <p className="text">{challengeContent.description}</p>
                    )}

                    {JSON.parse(challengeContent.choices).includes(
                      state.isTrueFalse
                    ) && (
                      <div className="start_task_btn_group">
                        {JSON.parse(challengeContent.choices).map(
                          (el, index) => {
                            return (
                              <div
                                key={el}
                                class="start_tast_btn"
                                onClick={() => myResponse(el)}
                              >
                                <a href="#">
                                  {el.charAt(0).toUpperCase() + el.slice(1)}{" "}
                                  <img
                                    src={images["btn_arrow.svg"]}
                                    alt="img"
                                  />
                                </a>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                    {JSON.parse(challengeContent.choices).includes(
                      state.isBuySell
                    ) && (
                      <div
                        class="updown_btnbox sell_buy"
                        style={{ marginTop: "21px" }}
                      >
                        {JSON.parse(challengeContent.choices).map(
                          (el, index) => {
                            return (
                              <button key={el} onClick={() => myResponse(el)}>
                                <p>
                                  {el.charAt(0).toUpperCase() + el.slice(1)}
                                </p>
                              </button>
                            );
                          }
                        )}
                      </div>
                    )}
                    {JSON.parse(challengeContent.choices).includes(
                      state.isUpDown
                    ) && (
                      <div class="updown_btnbox" style={{ marginTop: "21px" }}>
                        {JSON.parse(challengeContent.choices).map(
                          (el, index) => {
                            return (
                              <button key={el} onClick={() => myResponse(el)}>
                                <img src={images[`${el}.svg`]} alt={el} />
                                <p>
                                  {el.charAt(0).toUpperCase() + el.slice(1)}
                                </p>
                              </button>
                            );
                          }
                        )}
                      </div>
                    )}
                  </>
                ))}
            </div>
          </div>

          <div className="read_more_btn">
            {state.response && !state.challenge_complete && (
              <button type="button" onClick={continueChallengeContent}>
                CONTINUE
              </button>
            )}
            {state.response && state.challenge_complete && (
              <button type="button" onClick={finishChallenge}>
                FINISH LESSON
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
