import React from "react";

export default function PreLoader() {
  //   return (
  //     <div className="preloader">
  //     <div className="loader_spin"></div>
  // </div>)

  return (
    <div className="preloader_flex">
      <div className="ytp-spinner">
        <div>
          <div className="ytp-spinner-container">
            <div className="ytp-spinner-rotator">
              <div className="ytp-spinner-left">
                <div className="ytp-spinner-circle"></div>
              </div>
              <div className="ytp-spinner-right">
                <div className="ytp-spinner-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
