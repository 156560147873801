import React, { useState, useEffect } from "react";

export default function TextPage({ lesson }) {
  const [content, setContent] = useState();

  useEffect(() => {
    if (typeof lesson.content === "string") {
      setContent(JSON.parse(lesson.content));
    } else {
      setContent(lesson.content);
    }
  }, [lesson.content]);
  console.log(lesson.type, content);
  return (
    <div className="content_box">
      {content && (
        <>
          {content.title && <h3 className="title" style={{marginTop:"23px"}}>{content.title}</h3>}
          {content.text && <p className="text" style={{marginTop:"23px"}}>{content.text}</p>}
          {content.image && (
            <div className="img_box">
              <img src={content.image} alt="chapter" />
            </div>
          )}
        </>
      )}
    </div>
  );
}
