import React, { useEffect, useState } from "react";
import images from "../../../Assets/Images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Auth from "../../../helpers/Auth";
import Footer from "../../component/Footer";
import Coin from "../../component/Coin";
import Skeleton from "../../component/Skeleton";

export default function Profile() {
  const { http, token, logout, user } = Auth();
  const location = useLocation();
  const [currentYear, setCurrentYear] = useState(getDate());
  const navigate = useNavigate();
  const [currentLeague, setCurrentLeague] = useState([]);
  const [rank,setRank] = useState();
  function getDate() {
    const today = new Date();
    return today.getFullYear();
  }

  useEffect(() => {
    http
      .get(`/get-current-league/${user.id}`)
      .then((res) => {
        // console.log(res);
        setRank(res.data.data.rank[0])
        setCurrentLeague(res.data.data.league);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function logoutUser() {
    // console.log(token)
    if (token != undefined) {
      logout();
    }
  }
  return (
    <>
      <div className="main_container">
        <div className="top_header">
          <div></div>
          <div className="book_header_text">
            <p>Profile</p>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="coin_store"
            onClick={() => navigate("/profile/settings")}
          >
            <img src={images["settings.svg"]} alt="" />
          </div>
        </div>
        <div className="profile_header">
          <div className="profile_name">
            <h3>{user.name}</h3>
            <p>{user.email}</p>
          </div>
          <div className="profile_img">
            <img src={images["profile_img.svg"]} alt="profile" />
          </div>
        </div>
        <div className="profile_container">
          <h3 className="profile_section_tag">Balance</h3>
          <div className="available_box">
            <p>Available Cash</p>
            <ul className="amount_list">
              <li>
                <img src={images["coin.svg"]} alt="coin" />
              </li>
              <li>
                $
                <span>
                  <Coin only_balance={true} />
                </span>
              </li>
            </ul>
          </div>
          <h3 className="profile_section_tag">Leaderboard</h3>
          <div className="apprentice_box">
            <div className="img_box">
              <img src={images["current.png"]} alt="current" />
            </div>
            <div className="content">
              <p className="league-title">Apprentice</p>
              <p className="stat-title">Ranking: {rank&&rank.rank}</p>
            </div>
          </div>
          <h3 className="profile_section_tag">Milestones</h3>
          <div className="milestones_box_container">
            {currentLeague &&currentLeague.length>0?
              currentLeague.map((el) => {
                return (
                  <div key={el.id} className="milestones_box">
                    <div className="milestones_imgBox">
                      <img src={el.image} alt="lvl2" />
                    </div>
                    <div className="milestones_conetntBox">
                      <div className="milestones_header">
                        <h4>{el.name}</h4>
                        <p>{el.task_complete>0?el.task_complete:0}/{el.target_tasks}</p>
                      </div>
                      <div className="progress-bar">
                        <div className="line" style={{width:el.task_complete>0?Math.round(el.task_complete*100/el.target_tasks)+"%":"0%"}}></div>
                      </div>
                      <p className="achievement-description">
                        {el.description}
                      </p>
                    </div>
                  </div>
                );
              }):(
                <Skeleton/>
              )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
