import React, { useEffect, useState } from "react";
import images from "../../../Assets/Images";
import Auth from "../../../helpers/Auth";
import { useNavigate, useParams } from "react-router-dom";

export default function JoinChallenge() {
  const { http, user } = Auth();
  const params = useParams();
  const navigate = useNavigate();
  const [challenge, setChallenge] = useState();
  const [state, setState] = useState({});
  useEffect(() => {
    http
      .get(`/challenges/${user.id}/${params.challenge_id}`)
      .then((res) => {
        console.log(res);
        setChallenge(res.data.data.challenges);
        setState({
          ...state,
          select_task: res.data.data.daily_challenge_info.id,
          day: res.data.data.daily_challenge_info.day,
          daily_challenge_info: res.data.data.daily_challenge_info,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const startTask = () => {
    const result = http.post(`/challenges-start`, {
      user_id: user.id,
      challenge_id: params.challenge_id,
      daily_challenge_id: state.select_task,
    });
    navigate(`/challenges/${params.challenge_id}/tasks/${state.select_task}`);
  };
  return (
    <div className="main_container">
      <div className="top_header_book_inner investing_top">
        <div className="header_book_inner_wrapper">
          <div
            className="book_inner_left_arrow"
            onClick={() => navigate(`/challenges`)}
          >
            <img
              src={images["book_inner_arrow.png"]}
              alt="img"
              className="img_fluid"
            />
          </div>
          <div className="book_header_text">
            <p>{challenge && challenge.name}</p>
          </div>
          <div className="book_inner_sideBar">
            {/* <img
              src={images["question_mark.svg"]}
              alt="img"
              className="img_fluid"
            /> */}
          </div>
        </div>
      </div>
      <div className="investing_main">
        <div className="investing_top_img">
          {/* <img src="img/investing_top_img.png" alt=""> */}
        </div>
        <div className="investing_progress_div">
          <div className="investing_progress_top">
            <div className="investing_progress_head">
              <p>Your progress</p>
            </div>
            <div className="investing_progress_day_text">
              <p>
                <span>{state && state.day} </span>out of 28
              </p>
            </div>
          </div>
          <div className="calender_div">
            <div className="calender_top">
              <div className="calender_text">
                <p>Calendar</p>
              </div>
              <div className="calender_divider">
                <span></span>
              </div>
            </div>

            <div className="day_count">
              {challenge &&
                challenge.daily_challenges.map((el) => (
                  <div
                    key={el.id}
                    className={
                      state.day === el.day
                        ? "per_day_select day_selected"
                        : "per_day_select"
                    }
                    onClick={() => setState({ ...state, select_task: el.id })}
                  >
                    <div className="day_number">
                      <p>{el.day}</p>
                    </div>
                    <div className="day_select_circle">
                      <div className="day_select_circle_outer">
                        <div className="day_select_fill"></div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="read_more_btn">
            <button type="button" onClick={startTask}>
              Start Task
            </button>
          </div>
        </div>

        <div className="investing_divider">
          <span></span>
        </div>
        <div className="investing_bottom">
          <div className="investing_dec_div">
            <div className="investing_title">
              <p>
                {state.daily_challenge_info &&
                  `Day ${state.daily_challenge_info.day} : ${state.daily_challenge_info.name}`}
              </p>
            </div>
            <div className="investing_small_para">
              <p>
                Learn how the best minds and investors from around the world
                make decisions
              </p>
            </div>
            <div className="investing_small_title">
              <p>Scenario</p>
            </div>
            <div className="investing_big_para">
              <p>
                {state.daily_challenge_info &&
                  state.daily_challenge_info.scenario}
              </p>
            </div>
            {challenge && (
              <div class="key_skill_div">
                <p class="book_details_top_text">Key skills</p>
                <div class="key_skill_wrapper">
                  {JSON.parse(challenge.skills).map((el,index) => (
                    <div key={index} class="key_skill_item">
                      <p>{el}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="investing_testi_div">
            {state.daily_challenge_info && (
              <div>
                <div className="investing_testi_head">
                  <p>
                    {JSON.parse(state.daily_challenge_info.citation).author}
                  </p>
                </div>
                <div className="investing_test_para">
                  <p>
                    {JSON.parse(state.daily_challenge_info.citation).content}
                  </p>
                </div>
              </div>
            )}

            <div className="investing_test_icon">
              {/* <img src="img/investing_testi_icon.svg" alt="img"> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
