import React, { useState, useEffect } from "react";
import images from "../../../Assets/Images";

export default function ChoicePage({ lesson, lessonStep, lessonState }) {
  const [content, setContent] = useState();
  const [state, setState] = useState({
    submit: false,
    options:-1,
    hint:false
  });
  useEffect(() => {
    lessonState((prev) => {
      return { ...prev, lesson_step: false };
    });
    if (typeof lesson.content === "string") {
      setContent(JSON.parse(lesson.content));
    } else {
      setContent(lesson.content);
    }
  }, [lesson.content]);
  // console.log(lesson.type, content);
  const myResponse = (res, index) => {
    if (res.is_correct) {
      setState({ ...state, options: index, answer: true });
    } else {
      setState({ ...state, options: index, answer: false });
    }
  };
  const submitAnswer = () => {
    setState({ ...state, submit: true });
    setTimeout(() => {
      lessonStep("add_by_one");
      lessonState((prev) => {
        return { ...prev, lesson_step: true,answer:state.answer };
      });
    }, 400);
  };
  return (
    <div className="content_box">
      {content && (
        <>
          {content.title && <h3 style={{marginBottom:"17px"}} className="title">{content.title}</h3>}
          {content.text && <p className="text">{content.text}</p>}
          {content.image && (
            <div className="img_box">
              <img src={content.image} alt="chapter" />
            </div>
          )}
          <hr style={{marginBottom:"13px",marginTop:"13px"}}></hr>
          {content.question && <h3 className="title" style={{fontSize:"17px"}}>{content.question}</h3>}
          <div className="selectBox_container" style={{ marginTop: "21px" }}>
            {content.choices &&
              content.choices.map((el, index) => {
                return (
                  <label
                    key={index}
                    htmlFor={"ans" + index}
                    className={
                      state.options === index && state.submit&&
                      (state.answer ? "correct" : "incorrect")
                    }
                    onClick={() => myResponse(el, index)}
                    style={
                      state.submit === false
                        ? state.options === index
                          ? { border: "1px solid #795af6" }
                          : { border: "" }
                        : { border: "" }
                    }
                  >
                    <input type="radio" name="ans" id={"ans" + index} />

                    <p>{el.text}</p>
                    <div className="check_box"></div>
                  </label>
                );
              })}
          </div>
          {
            content.hint&&(
              <>
              <div style={{display:"flex",justifyContent:"end",cursor:"pointer",color:"orange"}}>
                <h4  onClick={()=>setState({...state,hint:!state.hint})}>Use a hint</h4>
              </div>
              {
                state.hint&&(
                  <div style={{paddingBottom:"11px"}} class="">
                  <div class="content_box">
                  <p>{content.hint}</p>
                </div>
              </div>
                )
              }
          
              </>
            )
          }
          {state.options >-1 && (
            <div className="read_more_btn">
              <button type="button" onClick={submitAnswer}>
                Submit
              </button>
            </div>
          )}
           {state.submit &&
        (state.answer ? (
          <div class="correct_popup">
            <div class="img_box">
              <img src={images["correct.svg"]} alt="" />
            </div>
            <div class="content_box">
              <p>Good job</p>
            </div>
          </div>
        ) : (
          <div class="incorrect_popup">
            <div class="img_box">
              <img src={images["incorrect.svg"]} alt="" />
            </div>
            <div class="content_box">
              <p>Oops</p>
            </div>
          </div>
        ))}
        </>
      )}
     
    </div>
  );
}
