import React, { useEffect, useState } from "react";
import "./Assets/css/Coursiv.css";
import "./Assets/css/finiva.css";
import "./Assets/css/ui-color.css";
import "./Assets/css/onboarding.css";
import Auth from "./helpers/Auth";
import GuestUser from "./route/GuestUser";
import AuthUser from "./route/AuthUser";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import VerifyAccount from "./templates/component/VerifyAccount";

function App() {
  const { getToken, user, http } = Auth();
  const [leadStatus, setLeadStatus] = useState();
  useEffect(() => {
    if (getToken()) {
      http
        .get(`/user-leadstatus/${user.id}`)
        .then((res) => {
          // console.log(res.data);
          setLeadStatus(res.data.is_lead_complete);
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  }, []);
  // console.log(getToken());
  if (!getToken()) {
    return <GuestUser />;
  } else {
    return (
      <Provider store={store}>
        <AuthUser />
        {/* {(leadStatus === 0||leadStatus===false) && (
          <VerifyAccount leadStatus={leadStatus} />
        )} */}
      </Provider>
    );
  }
}

export default App;
