import React from "react"

export default function Skeleton() {
	return (
		<div className="skeleton">
			<div className="wrapper">
				<div className="wrapper-cell">
					<div className="image"></div>
					<div className="text">
						<div className="text-line"> </div>
						
					</div>
				</div>
				<div className="wrapper-cell">
					<div className="image"></div>
					<div className="text">
						<div className="text-line"></div>
						
					</div>
				</div>
				<div className="wrapper-cell">
					<div className="image"></div>
					<div className="text">
						<div className="text-line"></div>
						
					</div>
				</div>
				
			</div>
		</div>
	)
}
