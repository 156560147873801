import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import images from "../../../Assets/Images";
import Auth from "../../../helpers/Auth";
import { fetchWalletBalance } from "../../../redux/features/wallet/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import DailyPopup from "../../component/DailyPopup";

export default function StockView() {
  const navigate = useNavigate();
  const { http, user } = Auth();
  const latest_trade_price = useRef(null);
  const params = useParams();
  const [assets, setAssets] = useState({});
  const [trade, setTrade] = useState();

  const [input, setInput] = useState({
    trade_trigger: false,
    next_url: "_blank",
    count: 0,
  });
  const [state, setState] = useState({
    tab: "chart",
    qty: 0,
    usd: 0.0,
    trade: false,
  });
  const [config, setConfig] = useState({
    frameElementId: `tradingview_1D`,
    symbol: params.asset.includes('-')?params.asset.split('-')[0]+params.asset.split('-')[1]:params.asset,
    range: "1D",
  });
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.wallet.balance);

  const getUserTradeDetails = () => {
    http
      .get(`/user-trade-details/${params.asset}/${user.id}`)
      .then((res) => {
        setTrade(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSimulatorStockDetails = () => {
    http
      .get(`/simulator-stock-details/${params.asset}`)
      .then((res) => {
        // console.log(res.data.data);
        if (
          res.data.data.latest_price === null ||
          res.data.data.latest_price <= 0
        ) {
          res.data.data.latest_price = res.data.data.close_price;
        }
        setAssets(res.data.data);
        // getLatestTradePrice(res.data.data.type, res.data.data.ticker_name);
        // getLatestTradePrice(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeStockPrice = () => {
    if (assets) {
      let change_by = assets.difference;
      let variation =
        ((Math.random() * 10000000) % change_by) *
        (Math.floor(Math.random() * 10000) % 2 ? 1 : -1);
      let latest_price = assets.latest_price + variation;

      setAssets({ ...assets, latest_price: latest_price });
    }
  };

  // const getLatestTradePrice = (type, ticker) => {
  //   // old_url = /latest-trade-price/${type}/${ticker}
  //   http
  //     .post(`/latest-trade-price/${assets.type}/${assets.ticker_name}`, {
  //       // next_url: input.next_url,
  //       assets:JSON.stringify([assets])
  //     })
  //     .then((res) => {
  //       // console.log(res.data.data);
  //       if (assets && assets.type) {
  //         setAssets({
  //           ...assets,
  //           close_price: res.data.data.latest_price || assets.close_price,
  //         });
  //       }
  //       // setInput({
  //       //   ...input,
  //       //   next_url: res.data.data.apiUrl,

  //       //   // usd:res.data.data.latest_price
  //       // });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  useEffect(() => {
    getSimulatorStockDetails();
    getUserTradeDetails();
    dispatch(fetchWalletBalance(user.id));
  }, []);

  useEffect(() => {
    const change_stock_price = setInterval(changeStockPrice, 3000); // Poll every 3 seconds
    const intervalId = setInterval(() => {
      // setInput({ ...input, count: input.count + 1 });
      // getLatestTradePrice(assets.type, assets.ticker_name); // Increment count every second
      getSimulatorStockDetails();
    }, 300000);
    return () => {
      clearInterval(change_stock_price);
      clearInterval(intervalId);
    };
  }, [assets]);

  const buySellStock = (type) => {
    if (state.qty > 0) {
      setState({
        ...state,
        buy_sell: true,
        trade_popup: true,
        trade: true,
        qty: 0,
        usd: 0.0,
      });
      http
        .post(`/simulator-stock-buy-sell`, {
          type: type,
          ltp: assets.latest_price,
          qty: state.qty,
          simulator_id: assets.id,
          user_id: user.id,
          ticker: params.asset,
        })
        .then((res) => {
          getUserTradeDetails();
          dispatch(fetchWalletBalance(user.id));
          // setState({ ...state, trade: true,qty:0,usd:0.0});
          setTimeout(() => {
            setState({ ...state, trade: false, trade_popup: false });
          }, 6000);
          // console.log(res);
        });
    } else {
      setState({ ...state, buy_sell: false });
      setTimeout(() => {
        setState({ ...state, trade: false });
      }, 600);
      // console.log("first");
    }
  };

  const setAssetQty = (e) => {
    if (e.target.value < 0) return;
    setState({
      ...state,
      usd: (assets.latest_price * e.target.value).toFixed(4),
      qty: e.target.value,
    });
  };
  const setAssetPrice = (e) => {
    if (e.target.value < 0) return;
    setState({
      ...state,
      usd: e.target.value,
      qty: (e.target.value / assets.latest_price).toFixed(4),
    });
  };
  const activeTab = (type) => {
    setState({ ...state, tab: type });
  };
  return (
    <>
      <div className="main_container">
        <div className="top_header">
          <div
            className="logo_box"
            onClick={() => navigate("/simulator/buy-assets")}
          >
            <img src={images["left_ang.svg"]} alt="" />
          </div>
          <div>
            <p className="stocks_name">{assets && assets.ticker_name}</p>
          </div>
          <div className="coin_store"></div>
        </div>
        <div className="stocframeElementIdks_view_box">
          <div className="stock_tabBox">
            <ul>
              <li
                className={state.tab === "chart" ? "active":""}
                onClick={() => activeTab("chart")}
              >
                Chart
              </li>
              <li
                className={state.tab === "info" ? "active":""}
                onClick={() => activeTab("info")}
              >
                Details
              </li>
            </ul>
          </div>
        </div>
        <div>
          {state.tab === "chart" && (
            <>
              <div className="view_box">
                {config && (
                  <iframe
                    title="advanced chart TradingView widget"
                    lang="en"
                    id={"tradingview_" + config.range}
                    frameBorder="0"
                    allowtransparency="true"
                    scrolling="no"
                    allowFullScreen={true}
                    src={`https://s.tradingview.com/widgetembed/?frameElementId=tradingview_${config.range}&symbol=${config.symbol}&interval=${config.range}&theme=light&style=1&toolbarbg=f1f3f6&withdateranges=false&hide_top_toolbar=true&saveimage=false&studies=[]`}
                    style={{
                      width: "100%",
                      height: "100%",
                      margin: "0px !important",
                      padding: "0px !important",
                    }}
                  ></iframe>
                )}
              </div>
              <div className="chapter_box buy_sell_inner">
                <ul className="period_tab">
                  <li
                    className={config.range === "1D" ? "active" : ""}
                    onClick={() =>
                      setConfig({
                        ...config,
                        range: "1D",
                        frameElementId: "tradingview_1D",
                      })
                    }
                  >
                    1D
                  </li>
                  <li
                    className={config.range === "5D" ? "active" : ""}
                    onClick={() =>
                      setConfig({
                        ...config,
                        range: "5D",
                        frameElementId: "tradingview_5D",
                      })
                    }
                  >
                    5D
                  </li>
                  <li
                    className={config.range === "1M" ? "active" : ""}
                    onClick={() =>
                      setConfig({
                        ...config,
                        range: "1M",
                        frameElementId: "tradingview_1M",
                      })
                    }
                  >
                    1M
                  </li>
                  <li
                    className={config.range === "3M" ? "active" : ""}
                    onClick={() =>
                      setConfig({
                        ...config,
                        range: "3M",
                        frameElementId: "tradingview_3M",
                      })
                    }
                  >
                    3M
                  </li>
                  <li
                    className={config.range === "6M" ? "active" : ""}
                    onClick={() =>
                      setConfig({
                        ...config,
                        range: "6M",
                        frameElementId: "tradingview_6M",
                      })
                    }
                  >
                    6M
                  </li>
                </ul>
                {/* <div className="how_itWork_box">
            <div className="content_box">
              <img src={images["fire.svg"]} alt="fire" />
              <p>
                <span>Wondering if it's the right time to sell?</span> Our guide
                helps you make informed decisions on when to cash in!
              </p>
            </div>
            <button>See how it works</button>
          </div> */}

                <div className="sell_buyBox">
                  <div className="convert_dollarBox">
                    <div className="input_box">
                      <label>{assets && assets.ticker_name}</label>
                      <input
                        type="number"
                        placeholder="0"
                        value={state.qty}
                        onChange={setAssetQty}
                      />
                    </div>
                    <div className="input_box">
                      <label>USD</label>
                      <input
                        type="number"
                        placeholder="0.00"
                        value={state.usd}
                        onChange={setAssetPrice}
                      />
                    </div>
                  </div>
                  {state.buy_sell === false && (
                    <>
                      {!(state.qty > 0) && (
                        <p
                          style={{
                            color: "rgb(212, 51, 51)",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          Quantity filed required.
                        </p>
                      )}
                    </>
                  )}
                  <div className="availableTotal_list">
                    <ul>
                      <li>
                        <p>Available cash</p> <span>${balance}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="sellBuy_button_container">
                    <button onClick={() => buySellStock("sell")}>
                      <p>Sell</p>
                      <h5>{assets.latest_price&&assets.latest_price.toFixed(2)}</h5>
                    </button>
                    <button onClick={() => buySellStock("buy")}>
                      <p>Buy</p>
                      <h5 ref={latest_trade_price}>
                        {assets.latest_price&&assets.latest_price.toFixed(2)}
                      </h5>
                    </button>
                  </div>
                </div>
                <div className="courses_titleBar_1">
                  {(trade&&assets)? (
                    <table>
                      <thead>
                        <tr>
                          <th>Qty</th>
                          <th>Avg Price</th>
                          <th>LTP</th>
                          <th>P/L</th>
                        </tr>
                      </thead>
                      <tbody>
                        {trade.trade_transctions.map((el) => {
                          return (
                            <tr key={el.id}>
                              <td>{el.left_qty}</td>
                              <td>{el.order_place_price}</td>
                              <td>{assets.latest_price && parseFloat(assets.latest_price).toFixed(2)}</td>
                              <td>
                                {el.type === "sell" &&
                                  ((
                                    (assets.latest_price -
                                      el.order_place_price) *
                                    el.left_qty
                                  ).toFixed(2) > 0 ? (
                                    <span
                                      style={{
                                        color: "rgb(212, 51, 51)",
                                      }}
                                    >
                                      {(
                                        (assets.latest_price -
                                          el.order_place_price) *
                                        el.left_qty
                                      ).toFixed(2)}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: "rgb(25, 170, 50)",
                                      }}
                                    >
                                      {(
                                        (assets.latest_price -
                                          el.order_place_price) *
                                        -el.left_qty
                                      ).toFixed(2)}
                                    </span>
                                  ))}

                                {el.type === "buy" &&
                                  ((
                                    (assets.latest_price -
                                      el.order_place_price) *
                                    el.left_qty
                                  ).toFixed(2) > 0 ? (
                                    <span
                                      style={{
                                        color: "rgb(25, 170, 50)",
                                      }}
                                    >
                                      {(
                                        (assets.latest_price -
                                          el.order_place_price) *
                                        el.left_qty
                                      ).toFixed(2)}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: "rgb(212, 51, 51)",
                                      }}
                                    >
                                      {(
                                        (assets.latest_price -
                                          el.order_place_price) *
                                        el.left_qty
                                      ).toFixed(2)}
                                    </span>
                                  ))}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div style={{ textAlign: "center", marginTop: "29px" }}>
                      No active Trade
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {state.tab === "info" && (
            <div>
              <div className="asset_container">
                <ul>
                  <li>
                    <a href="#">
                      <div className="info_box">
                        <div className="img_box">
                          <img src={assets.image} alt="company_logo" />
                        </div>
                        <div className="content_box">
                          <p className="name">{assets.name}</p>
                          <p className="ticker">{assets.ticker_name}</p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <h3>Abouts {assets.ticker_name}</h3>
              <p>{assets.description}</p>
            </div>
          )}
        </div>
        {state.trade_popup === true && (
          <div
            className={
              state.trade_popup === true
                ? "stock_order_popup active"
                : "stock_order_popup"
            }
          >
            {state.trade === true ? (
              <div className="conform_box">
                <div className="icon_box">
                  <i className="fa-solid fa-check"></i>
                </div>
                <p>You have bought 1.00 AAPL at 230.64</p>
              </div>
            ) : (
              <div className="processing_box">
                <div className="processing_animation"></div>
                <p>Processing your order...</p>
              </div>
            )}
          </div>
        )}
      </div>
      <DailyPopup />
    </>
  );
}
